import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import "./styles/aboutSideMenu.scss";

export default function LocationsSideMenu() {
  const data = useStaticQuery(graphql`
    query LocationsSideMenuLinks {
      site {
        siteMetadata {
          locationsMenu {
            link
            name
          }
        }
      }
    }
  `);

  return (
    <div id="admissionsOverviewSideMenu">
      <Row className="mt-5 p-3 white-bg">
        <Col>
          <Nav
            defaultActiveKey={data.site.siteMetadata.locationsMenu.link}
            className="flex-column"
          >
            <h4 className="menu-title">Explore Other Centers</h4>
            {data.site.siteMetadata.locationsMenu.map((link, index) => (
              <Nav.Item id={index++} key={index++}>
                <Link to={link.link}>
                  <Nav.Link as="span" eventKey={link.link}>
                    <FontAwesomeIcon
                      icon={faChevronCircleRight}
                      color="#0078ae"
                    />
                    {link.name}
                  </Nav.Link>
                </Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>
    </div>
  );
}
